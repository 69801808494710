import Image from "next/image";
import React, {useEffect, useState} from "react";

import {
    Select, SelectItem, Input, Button, Link,
    Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, ScrollShadow
} from "@nextui-org/react";

import { DEOCESE_DEFAULT } from '@/components/helpers/constant.js';
import ChurchesBlock from "@/components/shared/churchs-block";
import sharedService from "@/services/shared-service";

export default function SearchChurchs(deanerys) {
    const deanerysData = deanerys.data;

    const [churchs, setChurches] = useState({ total: 0});
    const [timeMass, setTimeMass] = useState();
    const [churchName, setChurchName] = useState();
    const [appointment, setAppointment] = useState(DEOCESE_DEFAULT);
    const {isOpen, onOpen, onClose} = useDisclosure();

    const fetchData = async () => {
        let payload = {
            timeMass: timeMass || '',
            churchName: churchName || '',
            appointmentID: appointment
        }
        const response = await fetch('api/churchs', {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        setChurches(await response.json());
    };

    // Docs: https://react.dev/blog/2022/03/29/react-v18#new-strict-mode-behaviors
    useEffect(() => {
        fetchData();
    }, []);

    const handleSearch = (event) => {
        event.preventDefault();
        fetchData();
    };

    const openModal = (event) => {
        onOpen();
    }

    return (
        <div className="search-churchs">
            <div className="search-churchs-data lg:grid grid-cols-6 gap-4 mx-auto py-4 px-4 bg-primary text-white">
                <div className="mb-3 lg:m-auto">
                    <h3 className="text-2lg font-bold">GIÁO PHẬN</h3>
                </div>

                <div className="mb-3 lg:my-auto rounded-[5px]">
                    <Select
                        items={deanerysData}
                        size="sm"
                        placeholder="Chọn Giáo Hạt"
                        defaultSelectedKeys={[DEOCESE_DEFAULT]}
                        className="bg-white text-gray-500 rounded-[5px]"
                        onChange={(e) => setAppointment(e.target.value)}
                    >
                        {deanerysData.value.map((appointment) => {
                            return (
                                <SelectItem key={appointment.id}>
                                    {appointment.name}
                                </SelectItem>
                            )
                        })}
                    </Select>
                </div>

                <div className="mb-3 lg:my-auto">
                    <Input
                        size="lg"
                        key="none"
                        radius="none"
                        type="text"
                        placeholder="Nhập Tên Giáo Xứ"
                        classNames={{
                            inputWrapper: "font-semibold text-gray-500 rounded-[5px]"
                        }}
                        onChange={(e) => setChurchName(e.target.value)}
                    />
                </div>

                <div className="lg:col-span-2 lg:flex items-center">
                    <div className="time-mass mb-3 lg:my-0 lg:mx-5">
                        <Input
                            size="lg"
                            key="none"
                            radius="none"
                            type="text"
                            placeholder="Giờ Lễ"
                            classNames={{
                                inputWrapper: "font-semibold text-gray-500 rounded-[5px]"
                            }}
                            onChange={(e) => setTimeMass(e.target.value)}
                        />
                    </div>

                    <div className="flex text-center">
                        <div className="button w-1/2 mb-3 lg:my-0 mx-auto lg:mx-5 gap-5">
                            <Button onClick={handleSearch} size="md"
                                    className="rounded-[10px] text-white font-bold bg-link">
                                TÌM KIẾM
                            </Button>
                        </div>

                        <div className="button w-1/2 mb-3 lg:my-0 mx-auto lg:mx-5">
                            <Button onPress={() => openModal()} size="md"
                                    className="rounded-[10px] text-primary font-bold bg-custom">
                                PHÓNG TO
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="m-auto">
                    <h3 className="text-2lg text-center">Giáo Xứ - Giáo Hạt - Giờ Lễ</h3>
                </div>
            </div>

            {(churchs.total > 0) && <>
                <ScrollShadow hideScrollBar orientation="horizontal" className="w-full h-[400px]">
                    <ChurchesBlock churchData={churchs} isPagination={false} />
                </ScrollShadow>
            </>}


            <Modal isOpen={isOpen} onClose={onClose} size="full" scrollBehavior="inside" backdrop="blur">
                <ModalContent>
                    {(onCloseModal) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1 text-3lg font-normal">Danh Sách Giáo
                                Xứ</ModalHeader>
                            <ModalBody>
                                <div className="">
                                    <div className="search-modal--inputs lg:grid grid-cols-10 bg-primary py-3">
                                        <div className="lg:col-span-3 my-5 mx-5">
                                            <Select
                                                items={deanerysData}
                                                size="sm"
                                                placeholder="Chọn Giáo Hạt"
                                                onChange={(e) => setAppointment(e.target.value)}
                                                className=""
                                            >
                                                {deanerysData.value.map((appointment) => {
                                                    return (
                                                        <SelectItem key={appointment.id}>
                                                            {appointment.name}
                                                        </SelectItem>
                                                    )
                                                })}
                                            </Select>
                                        </div>
                                        <div className="lg:col-span-3 my-5 mx-5 mac:my-5">
                                            <Input
                                                size="lg"
                                                key="none"
                                                radius="sm"
                                                type="text"
                                                placeholder="Nhập Tên Giáo Xứ"
                                                className="font-semibold"
                                                onChange={(e) => setChurchName(e.target.value)}
                                            />
                                        </div>
                                        <div className="lg:col-span-3 my-5 mx-5 mac:my-5">
                                            <Input
                                                size="lg"
                                                key="none"
                                                radius="sm"
                                                type="text"
                                                placeholder="Nhập giờ lễ định dạng 24h"
                                                className="font-semibold"
                                                onChange={(e) => setTimeMass(e.target.value)}
                                            />
                                        </div>
                                        <div
                                            className="lg:my-5 pb-5 lg:pb-0 pr-5 lg:pr-0 text-center col-span-3 lg:col-span-1 mx-auto">
                                            <Button size="lg" onClick={handleSearch} className="rounded bg-white">
                                                TÌM KIẾM
                                            </Button>
                                        </div>
                                    </div>

                                    <div className="bg-secondary">
                                        {(churchs) && churchs.value.map(church => {
                                            const churchName = church?.typeName + ' ' + church?.name;

                                            return (
                                                <>
                                                    <div key={church.id}
                                                         className="search-churchs--result grid grid-cols-2 lg:grid-cols-7 gap-4 py-5 mx-auto container items-center bg-secondary border-b border-gray-500">
                                                        <div className="result--img m-auto">
                                                            <Image className="flex justify-center"
                                                                   src={church.photoUrl ? sharedService.getOptimizeImage(church.photoUrl, 'small-post-view') : ""}
                                                                   alt={churchName}
                                                                   width={150}
                                                                   height={150}/>
                                                        </div>

                                                        <div className="result--informations leading-7 lg:hidden">
                                                            <p className="font-bold lg:hidden">{churchName}</p>
                                                            <p className="">
                                                                <Link href={'tel:' + church?.phoneNumber}>Điện
                                                                    thoại: {church?.phoneNumber}</Link>
                                                            </p>
                                                            <p className="">Dân số: {church?.population}</p>
                                                            <p className="">Số tín hữu: {church?.memberCount}</p>
                                                        </div>

                                                        <div
                                                            className="result--address ml-3 lg:ml-0 col-span-2 leading-7">
                                                            <p className="font-bold hidden lg:block">{church?.typeName + ' ' + church?.name}</p>
                                                            <p className="">
                                                                Địa chỉ: <Link aria-label={`Địa chỉ: ${church?.address}`}
                                                                href={church?.addressLink}>{church?.address}</Link>
                                                            </p>
                                                            <p className="">
                                                                Email: <Link aria-label={`Email: ${church?.email}`}
                                                                href={'mailto:' + church?.email}>{church?.email}</Link>
                                                            </p>
                                                        </div>

                                                        <div
                                                            className="result--informations ml-3 lg:ml-0 col-span-2 leading-7 hidden lg:block">
                                                            <p className="font-bold lg:hidden">{church?.typeName + ' ' + church?.name}</p>
                                                            <p className="">
                                                                <Link aria-label={`Điện thoại: ${church?.phoneNumber}`} href={'tel:' + church?.phoneNumber}>Điện
                                                                    thoại: {church?.phoneNumber}</Link>
                                                            </p>
                                                            <p className="">Dân số: {church?.population}</p>
                                                            <p className="">Số tín hữu: {church?.memberCount}</p>
                                                        </div>

                                                        <div
                                                            className="result--time-mass ml-3 lg:ml-0 col-span-2 leading-7">
                                                            <p className="font-bold text-red-700">Giờ lễ:</p>
                                                            <p className="">
                                                                Chiều thứ Bảy: <span
                                                                dangerouslySetInnerHTML={{__html: `${church.masses?.chieu_thu_bay ? church.masses?.chieu_thu_bay.replaceAll(':', 'g') : ''}`}}></span>
                                                            </p>
                                                            <p className="">
                                                                Chúa Nhật: <span
                                                                dangerouslySetInnerHTML={{__html: `${church.masses?.chua_nhat ? church.masses?.chua_nhat.replaceAll(':', 'g') : ''}`}}></span>
                                                            </p>
                                                            <p className="flex">
                                                                <span className="">
                                                                    Ngày thường: <span
                                                                    dangerouslySetInnerHTML={{__html: `${church.masses?.ngay_thuong ? church.masses?.ngay_thuong.replaceAll(':', 'g') : ''}`}}></span>
                                                                </span>
                                                                <span className="flex-1 mx-4 text-red-700 font-bold">
                                                                    <Link aria-label={church?.name} href={"/nha-tho/" + church.id}>XEM THÊM</Link>
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    );
}
